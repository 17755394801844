<template>
  <div>
    <div class="wrapper"></div>
    <div class="main">
      <div style="flex: 1;" ></div>
      <button v-if="data.isStart == false" @click="init()">启动数字人</button>
      <template v-if="data.isStart">
        <div>介绍内容（点击按钮对方会说话）</div>
        <button class="send" @click="sendAData('你好')" >你好</button>
        <button class="send" @click="sendAData('人工牛黄甲硝唑胶囊用于急性智齿冠周炎、局部牙槽脓肿、牙髓炎、根尖周炎等')" >介绍人工牛黄甲硝唑胶囊</button>
        <button class="send" @click="sendAData('人工牛黄甲硝唑胶囊1.本品最严重不良反应为高剂量时可引起癫痫发作和周围神经病变，后者主要表现为肢端麻木和感觉异常。某些病例长期用药时可产生持续性周围神经病变。2.其他常见的不良反应有：（1）胃肠道反应，如恶心、食欲减退、呕吐、腹泻、腹部不适、味觉改变、口干、口腔金属味等。（2）可逆性粒细胞减少。（3）过敏反应，皮疹、荨麻疹、瘙痒等。（4）中枢神经系统症状，如头痛、眩晕、感觉异常、肢体麻木、共济失调和精神错乱等。（5）其他有发热、阴道念珠菌感染、膀胱炎、排尿困难、尿液颜色发黑等，均属可逆性，停药后自行恢复。')" >
          人工牛黄甲硝唑胶囊不良反应
        </button>
        <button class="send" @click="sendAData('人工牛黄甲硝唑胶囊1.致癌、致突变作用：动物试验或体外测定发现本品具致癌、致突变作用，但人体中未证实。2.使用中发生中枢神经不良反应，应及时停药。3.本品可干扰丙氨酸氨基转移酶、乳酸脱氢酶、三酰甘油、己糖激酶等的检验结果。4.用药期间不应饮用含酒精的饮料，因可引起体内乙醛蓄积，干扰酒精的氧化过程，导致双硫仑样反应，患者可出现腹部痉挛、恶心、呕吐、头痛、面部潮红等。5.肝功能减退者本品代谢减慢，药物及其代谢易在体内蓄积，应减量使用，并作血药浓度监测。6.本品可自胃液持续清除，某些放置胃管作吸引减压者，可引起血药浓度下降。血液透析时，本品及代谢物迅速被清除，故应用本品不需减量。7.念珠菌感染者应用本品，其症状会加重，需同时给抗真菌治疗。8.厌氧菌感染合并肾功能衰竭患者，给药间隔时间应由8小时延长至12小时。9.治疗阴道滴虫病时，需同时治疗其性伴侣。10.重复一个疗程前，应做白细胞计数。')" >
          人工牛黄甲硝唑胶囊注意事项
        </button>
        <button class="send" @click="sendAData('人工牛黄甲硝唑胶囊本品为复方制剂，其组份为每粒含甲硝唑0.2g,人工牛黄5mg')" >
          人工牛黄甲硝唑胶囊成分
        </button>
        <button class="send" @click="sendAData('人工牛黄甲硝唑胶囊甲硝唑可透过胎盘，迅速进入胎儿循环。动物试验发现口服给药对胎仔无毒性。甲硝唑对胎儿的影响尚无足够和严密的对照观察，因此孕妇禁用。甲硝唑在乳汁中浓度与血中相似，动物试验显示本品对幼鼠具致癌作用，故哺乳期妇女不宜使用。若必须用药，应中断授乳，并在疗程结束后24~48小时方可重新授乳')" >
          人工牛黄甲硝唑胶囊孕妇及哺乳期妇女用药
        </button>
      </template>
    </div>
  </div>
</template>
<style lang="less" scoped>
.wrapper {
  width: 100vw;
  height: 100vh;
  // 绝对定位在屏幕右上角
  position: absolute;
  top: 0;
  right: 0;
}
.main {
  position: relative;
  z-index: 1;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
button {
  font-size: 18px;
  background-color: red;
  color: white;
}
</style>
<script setup>
import AvatarPlatform, {
  PlayerEvents,
  SDKEvents,
} from "avatar-sdk-web";
// 引入vue ref
import { ref, reactive } from "vue";
const data = reactive({
  // 是否启动
  isStart: false,
});
// 数字人实例化
let avatarPlatform = null;
// 初始化
function init() {
  data.isStart = true;
  // 获取浏览器宽度
  let width = document.documentElement.clientWidth;
  // 向下取宽度到4的倍数
  width = width - (width % 4);
  // 获取浏览器高度
  let height = document.documentElement.clientHeight;
  // 向下取高度到4的倍数
  height = height - (height % 4);
  avatarPlatform = new AvatarPlatform();
  const config = {
    appId: "392d70a5",
    apiKey: "0fe58ec6a06f7da2271213dd67482078",
    apiSecret: "OWRmODMwN2I0N2QzNGQ5ZDM4Y2JiYWE1",
    sceneId: "", // 如果是交互平台创建的项目时 使用
    serverUrl: "wss://avatar.cn-huadong-1.xf-yun.com/v1/interact",
  };
  console.log("配置", config);
  avatarPlatform
    .setApiInfo(config)
    // 设置全局/连接 参数
    .setGlobalParams({
      stream: {
        protocol: "xrtc",
      },
      avatar: {
        avatar_id: "110117007", //授权的形象资源id
        width: width, // 虚拟人画面宽度
        height: height, //
      },
      tts: {
        vcn: "x4_yezi", //授权的声音资源id
      },
    });
  // 获取token, 也可以提前获取，在上一步设置
  avatarPlatform
    .start({
      wrapper: document.querySelector(".wrapper"),
    })
    .then(() => {
      console.log("connected &&  stream play successfully");
      // 注意这里仅是流可以播放， 如果是进页面在用户未交互网页时，代码自动连，
      // 第三步骤 player实例 可能收到PlayerEvents.playNotAllowed事件。
      // 您需要交互层面再次用户点击网页 并主动调用 player.resume() 恢复播放！！
      // 原因：受限于浏览器的自动播放策略
      // 监听#send的点击事件
      data.isStart = true;
    })
    .catch((e) => {
      // 连接或者流失败，会话停止。
      console.error(e.code, e.message, e.name, e.stack);
    });
}
// 发送一段文字
function sendAData(text) {
  // 这里是为了有声音
  avatarPlatform.player.resume();
  console.log("send");
  avatarPlatform.writeCmd("action", "A_Hello_l_F");
  avatarPlatform
    .writeText(
      text,
      {
        nlp: false,
        interactive_mode: 1,
      }
    )
    .catch((e) => {
      // Error
    });
}
</script>
